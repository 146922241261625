<template>
	<div class="addFeighttool">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<div class="baseInformtion">
				<div class="infortion">基本信息</div>
				<el-form-item label="活动名称" prop="Name">
					<el-input v-model="ruleForm.Name" placeholder="最多输入20个字" style="width:250px" clearable></el-input>
				</el-form-item>

				<el-form-item label="活动时间" prop="IsPermanent">
					<el-radio-group v-model="ruleForm.IsPermanent" @change="dateChange">
						<el-radio :label="0">指定时间
							<el-date-picker :disabled="ruleForm.IsPermanent==0?false:true" type="datetime" :picker-options="maxOptions"
							 placeholder="选择日期" v-model="ruleForm.StartTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
							~
							<el-date-picker :disabled="ruleForm.IsPermanent==0?false:true" type="datetime" placeholder="选择日期"
							 :picker-options="minOptions" v-model="ruleForm.EndTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
							 format="yyyy-MM-dd HH:mm:ss"></el-date-picker>

						</el-radio>
						</br>
						<el-radio :label="1" style="margin-top:20px">永久有效</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="包邮规则" prop="FullMoney">
					消费满
					<el-input style="width:200px;margin: 0 10px;" type="number" @blur="handleInputnumber($event)" v-model="ruleForm.FullMoney"
					 clearable></el-input>元
					<!-- <input v-model="ruleForm.FullMoney" style="margin:0 10px;padding:0 10px;width:200px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
					 @keydown="handleInput2" type="number">元 -->
				</el-form-item>
			</div>

			<div class="baseInformtion" style="padding-bottom: 200px;">
				<div class="infortion">活动信息</div>

				<div class="filter-container">
					<div class="filter-item" style="width:100px;text-align: right;vertical-align: top;font-size: 14px;"><span class="tablered">*</span>包邮区域:</div>

					<div class="filter-item" style="vertical-align: top;color:rgb(64, 158, 255);cursor: pointer;font-size: 14px;"
					 @click="selectAreas">选择包邮区域</div>
				</div>

				<div style="margin:0px 0 20px 130px;width:1000px" v-if="ruleForm.AreaNames.length">
					<span v-for="(item,index) in ruleForm.AreaNames" :key="index" style="margin:0 10px 10px 0;display: inline-block;font-size: 14px;">{{item}}</span>
				</div>

				<div class="filter-container">
					<div class="filter-item" style="width:100px;text-align: right;vertical-align: top;font-size: 14px;"><span class="tablered">*</span>包邮商品:</div>
						<el-radio  @change="changeRangeType" v-model="ruleForm.ActivityRangeType" :label="0">全部商品包邮</el-radio>
						<br>
						<el-radio  @change="changeRangeType" v-model="ruleForm.ActivityRangeType" :label="1" style="margin-left:130px;">指定商品包邮</el-radio>
						<div v-show='ruleForm.ActivityRangeType' class="filter-item" style="vertical-align: top;color:rgb(64, 158, 255);cursor: pointer;font-size: 14px;" @click="slectProduct">选择包邮商品</div>

					 
				</div>


				<div style="width:1100px;margin-left:120px;">
					<el-table :data="allData" style="width:1100px" v-if="allData.length">
						<el-table-column prop="Name" label="商品">
							<template slot-scope="scope">
								<div class="prucuct-name-content">
									<div class="imgs-boxs">
										<img :src='imgUrl+scope.row.ImgUrl' />
									</div>
									<div class="producrs-name">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>

									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="ProductPrice" label="价格">
						</el-table-column>
						<el-table-column prop="Stock" label="库存">
						</el-table-column>
						<el-table-column label="操作" width="270">
							<template slot-scope="scope">
								<span style="color:red;cursor: pointer;" @click="delectContect(scope.row,scope.$index)">删除</span>
							</template>
						</el-table-column>

					</el-table>

					<el-pagination v-if="TotalNum" style="margin-top:20px;float:right;" @size-change="handleSizeChangenum"
					 @current-change="handleCurrentChangenum" :current-page="currentPagenum" :page-sizes="[5, 10,15,20,25]" :page-size="pageSizenum"
					 layout="total, sizes, prev, pager, next, jumper" :total="TotalNum">
					</el-pagination>
				</div>


			</div>

		</el-form>


		<!-- 选择地区 -->

		<el-dialog title="选择包邮区域" :visible.sync="SetDialogVisible" width="700px" @opened="muneFun">

			<el-tree :data="menuList" :props="{children: '', label: 'name'}" show-checkbox default-expand-all
			 :default-checked-keys="slectKeys" node-key="id" ref="tree" highlight-current style="height:400px;overflow: auto;">
			</el-tree>
			<el-checkbox style="margin-top:25px;margin-left:13px;float:left;" v-model="checkedAll" @change="selectCurrentAll">当前页全选</el-checkbox>

			<div slot="footer" class="dialog-footer">
				<el-button @click="SetDialogVisible=false">取 消</el-button>
				<el-button type="primary" @click="submitEdit">确 定</el-button>
			</div>
		</el-dialog>

		<!-- 选择商品弹框 -->
		<el-dialog :visible.sync="selectVisables" width="1100px" title="选择商品">
			<SelectfrightProduce @getSelectList="getSelectList" :productData="productData" v-if="selectVisables"></SelectfrightProduce>
		</el-dialog>

		<div class="footer-tools">
			<el-button style="width:200px;margin-right: 10px;" @click="canselCopear">取 消</el-button>
			<el-button type="primary" style="width:200px" @click="saveToolData('ruleForm')" :loading="loading">保 存</el-button>
		</div>

		<!-- 选择包邮地区 -->
		<el-dialog title="选择包邮区域" :visible.sync="selectShipShow" width="900px" v-if="selectShipShow" @opened="muneFun1">
			<select-ship :defaultData='ruleForm.AreaIds' @getCheckedArea='getCheckedArea'></select-ship>
		</el-dialog>

	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		activityFreeDeliverySave,
		activityFreeDeliveryInfo
	} from '@/api/goods'
	import axios from 'axios'

	import areaData from '@/components/AreaData_soure.json'
	import SelectfrightProduce from '@/components/SelectMulProduce/SelectfrightProduce.vue'

	import selectShip from '../discountActivity/selectShipDialog.vue';

	export default {
		components: {
			SelectfrightProduce,
			selectShip
		},
		data() {
			var checkName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入活动名称'));
				} else if (!value.trim()) {
					return callback(new Error('请输入活动名称'));
				} else {
					return callback()
				}
			};
			var checkFullMoney = (rule, value, callback) => {
				if (value > 0) {
					return callback();
				} else if (value == 0) {
					return callback(new Error('包邮门槛需大于0'));
				} else {
					return callback(new Error('请填写包邮规则'));

				}
			};
			return {
				frightRadio:0,
				checkedAll: false,
				loading: false,
				imgUrl: config.IMG_BASE,
				slectKeys: [],
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now(); //如果没有后面的-8.64e7就是不可以选择今天的  data.Id=
					}
				},
				TotalNum: 0,
				currentPagenum: 1,
				pageSizenum: 5,
				productData: [],
				selectVisables: false,
				tableData: [],
				recordData: {},
				menuList: [],
				SetDialogVisible: false,
				ruleForm: {
					Name: '',
					IsPermanent: 0,
					StartTime: '',
					EndTime: '',
					FullMoney: '',
					AreaIds: [],
					AreaNames: [],
					ProductIdList: [],
					ActivityRangeType :0

				},
				rules: {
					Name: [{
							required: true,
							// message: '请输入活动名称',
							trigger: 'blur',
							validator: checkName
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20 个字符',
							trigger: 'blur'
						}
					],
					IsPermanent: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
					FullMoney: {
						required: true,
						validator: checkFullMoney,
						trigger: 'blur'
					},
				},
				allData: [],
				toolId: null,
				toolType: null,
				selectShipShow: false,

			}
		},

		beforeMount() {
			// this.menuList = areaData;
			this.toolId = this.$route.query.Id
			this.toolType = this.$route.query.type
			this.getDataList()
			this.getstartData()
		},
		computed: {
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				// let hour = d.getHours();
				// let minute = d.getMinutes();
				// let second = d.getSeconds();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		methods: {
			changeRangeType(){
				this.allData = []
				this.currentPagenum = 1
				this.pageSizenum = 5
				this.productData = []
				console.log('this',this.ruleForm.ActivityRangeType)
			},
			muneFun1() {
				let that = this;
			},
			getCheckedArea(ids, names) {
				this.ruleForm.AreaIds = ids;
				this.ruleForm.AreaNames = names;
				this.selectShipShow = false;
			},
			handleInputnumber(e) {
				e.target.value = e.target.value.match(/\d+(\.\d{0,2})?/) ? e.target.value.match(/\d+(\.\d{0,2})?/)[0] : ''
			},
			dateChange() {
				this.ruleForm.StartTime = '';
				this.ruleForm.EndTime = ''
			},

			selectCurrentAll() {

				if (this.checkedAll) {
					this.slectKeys = this.menuList.map(item => {
						return item.id
					})

				} else {
					this.slectKeys = []
					this.$refs.tree.setCheckedKeys([]);
				}



			},
			//获取地址数据
			getDataList() {
				var that = this
				axios.get('http://cdn.dkycn.cn/json/citylist-include.json', {})
					.then(function(response) {
						that.menuList = response.data
					})
					.catch(function(error) {
						console.log(error)
					})
			},

			// 初始化数据
			async getstartData() {
				try {
					if (this.toolId > 0) {
						let result = await activityFreeDeliveryInfo({
							Id: this.toolId
						})
						this.ruleForm = result.Result
						if (this.ruleForm.IsPermanent) {
							this.ruleForm.IsPermanent = 1
							this.ruleForm.EndTime = ''
							this.ruleForm.StartTime = ''
						} else {
							this.ruleForm.IsPermanent = 0
						}

						if (this.toolType == 2) {
							this.ruleForm.StartTime = '';
							this.ruleForm.EndTime = '';
						}

						if (this.ruleForm.StartTime == '2000-01-01 00:00:00' && this.ruleForm.EndTime ==
							'4000-01-01 00:00:00') {
							this.toolType = 2;
							this.ruleForm.StartTime = '';
							this.ruleForm.EndTime = '';
						}

						this.productData = result.Result.ProductIdList
						this.productData.map(item => {
							item.ProductId = item.Id;
							item.keys = item.Id;
						})

						let dataStart = this.pageSizenum * (this.currentPagenum - 1);
						let dataEnd = this.pageSizenum * this.currentPagenum
						this.allData = this.productData.slice(dataStart, dataEnd);
						this.TotalNum = this.productData.length
						this.slectKeys = result.Result.AreaIds

					}

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			// 取消操作
			canselCopear() {
				this.$confirm('您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$router.push({
						path: '/market/frightTool'
					});



				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消操作'
					});
				}).finally(() => {

				})
			},

			// 保存操作
			saveToolData(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.loading = true
							if (this.ruleForm.ActivityRangeType&&!this.ruleForm.AreaNames.length) {
								this.$message({showClose: true,
									type: 'error',
									message: '请选择包邮区域！'
								});

								return
							}

							if (this.ruleForm.ActivityRangeType&&!this.productData.length) {
								this.$message({showClose: true,
									type: 'error',
									message: '请选择包邮商品！'
								});

								return
							}
							let ProductIdList = []
							this.productData.map(item => {
								ProductIdList.push(item.ProductId)
							})

							let data = {
								Name: this.ruleForm.Name,
								IsPermanent: this.ruleForm.IsPermanent,
								StartTime: this.ruleForm.StartTime,
								EndTime: this.ruleForm.EndTime,
								FullMoney: this.ruleForm.FullMoney,
								AreaIds: this.ruleForm.AreaIds,
								AreaNames: this.ruleForm.AreaNames,
								ProductIdList: ProductIdList,
								ActivityRangeType :this.ruleForm.ActivityRangeType
							}
							if (this.toolId && this.toolType == 1) {
								data.Id = this.toolId
							} else {
								data.Id = 0
							}

							let result = await activityFreeDeliverySave(data)

							if (result.IsSuccess) {
								this.$message({showClose: true,
									type: 'success',
									message: '保存成功!'
								});
								this.$router.push({
									path: '/market/frightTool'
								});
							}
						} catch (e) {
							console.log(e)
						} finally {
							setTimeout(() => {
								this.loading = false
							}, 500)
						}

					} else {
						console.log('error submit!!');
						return false;
					}
				});


			},
			getSelectList(ishow, val) {
				this.productData = val;
				this.selectVisables = ishow
				this.ruleForm.ProductIdList = []
				if ((this.productData.length) % 5 == 0 && this.currentPagenum > 1) {
					this.currentPagenum = this.currentPagenum - 1
				}
				let dataStart = this.pageSizenum * (this.currentPagenum - 1);
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.allData = this.productData.slice(dataStart, dataEnd);
				this.TotalNum = val.length
				this.productData.map(item => {
					this.ruleForm.ProductIdList.push(item.ProductId)
				})

			},

			// 切换显示条数
			handleSizeChangenum(val) {
				this.pageSizenum = val
				let dataStart = this.pageSizenum * (this.currentPagenum - 1)
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.allData = this.productData.slice(dataStart, dataEnd);

			},
			// 翻页
			handleCurrentChangenum(val) {
				this.currentPagenum = val;
				let dataStart = this.pageSizenum * (this.currentPagenum - 1);
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.allData = this.productData.slice(dataStart, dataEnd);
			},

			// 选择包邮产品
			slectProduct() {
				this.selectVisables = true;
			},

			// 选择包邮工具
			selectAreas() {
				this.selectShipShow = true;
				// this.SetDialogVisible = true;
			},
			muneFun() {
				this.slectKeys = this.menuList
				let that = this;
			},

			// 删除子商品
			delectContect(record, index) {
				this.productData = this.productData.filter(item => {
					return item.ProductId != record.ProductId
				})

				this.TotalNum = this.productData.length

				let flagers = this.currentPagenum > Math.ceil(this.TotalNum / this.pageSizenum)
				if ((this.productData.length) % this.pageSizenum == 0 && this.currentPagenum > 1 && flagers) {
					this.currentPagenum = this.currentPagenum - 1;
				}

				let dataStart = this.pageSizenum * (this.currentPagenum - 1);
				let dataEnd = this.pageSizenum * this.currentPagenum
				this.allData = this.productData.slice(dataStart, dataEnd);


			},

			// 确定事件
			submitEdit() {
				this.jurisdictionList = [];
				this.ruleForm.AreaIds = [];
				this.ruleForm.AreaNames = [];
				let arrs = this.$refs.tree.getCheckedNodes();
				console.log(this.$refs.tree, arrs, '地址')

				this.SetDialogVisible = false;
				arrs.map(item => {
					this.ruleForm.AreaIds.push(item.id)
					this.ruleForm.AreaNames.push(item.name)
				})


			},

			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null

			},
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			}

		}
	}
</script>
<style lang="less">
	.addFeighttool {
		.baseInformtion {
			width: 100%;
			background: #fff;
			padding: 10px;
			margin-bottom: 12px;

			.infortion {
				margin-bottom: 20px;
				padding: 10px 0;
				border-bottom: 1px solid #ccc;
			}
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.tablered {
			color: red;
			vertical-align: -3px;
			margin-right: 5px;
		}

		.footer-tools {
			width: 100%;
			background: #fff;
			position: fixed;
			bottom: 0;
			background: #fff;
			padding: 10px 0;
			text-align: center;
		}
	}

	.prucuct-name-content {
		margin: 10px 0;
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-moz-box-align: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		align-items: center;
		-ms-flex-align: center;

		.imgs-boxs {
			width: 60px;
			height: 60px;
			margin-right: 10px;
		}

		img {
			width: 60px;
			height: 60px;
			display: block;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}

		.producrs-name {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
</style>

<template>
	<div class="contanier">
		<!-- table类型 -->
		<!-- <div class="table-container">
			<div class="area-content" v-for=" item in menuList" :key="item.id">
				<div class="left">
					<el-checkbox v-model="item.checked" @change="classifyChecked(item.id)">{{item.name}}</el-checkbox>
				</div>
				<div class="right">
					<div class="item" v-for="tt in item.child" :key="tt.id">
						<el-checkbox @change="changeSelectCity(tt.id)" v-model="tt.checked">{{tt.name}}</el-checkbox>
					</div>
				</div>
			</div>
		</div>-->
		<!-- 树形 -->
		<div class="table-container" style="display: flex;">
			<div style="width:300px">
				<el-tree
					:data="menuList1"
					show-checkbox
					node-key="id"
					ref="tree1"
					:props="{label:'name',children:'children'}"
					:default-checked-keys="defaultData"
					:default-expanded-keys="[1,2,3]"
				></el-tree>
			</div>

			<div style="width:300px">
				<el-tree
					:data="menuList2"
					show-checkbox
					node-key="id"
					ref="tree2"
					:props="{label:'name',children:'children'}"
					:default-checked-keys="defaultData"
					:default-expanded-keys="[4,5,6]"
				></el-tree>
			</div>

			<div style="width:300px">
				<el-tree
					:data="menuList3"
					show-checkbox
					node-key="id"
					ref="tree3"
					:props="{label:'name',children:'children'}"
					:default-checked-keys="defaultData"
					:default-expanded-keys="[7,8]"
				></el-tree>
			</div>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitEdit">确 定</el-button>
		</div>
	</div>
</template>
<script>
// import areaData from '@/components/AreaData_classify.json'
import util from '@/utils/util.js'
export default {
	name: 'selectPro',
	props: {
		defaultData: {
			type: Array
		},
		disableArr: {
			type: Array
		},
		isDisavled: {
			type: Array
		},
	},
	data () {
		return {
			menuList1: [],
			menuList2: [],
			menuList3: [],
			checkedList: [],
			multipleSelection: [],
		}
	},
	beforeMount () {
		this.initAreaData()
		this.$forceUpdate()
	},
	methods: {
		//获取城市数据
		initAreaData () {
			let addressList = util.getAddressList(this.disableArr);
			let menuList1 = [
				{ id: 1, name: '华东', children: [], disabled: false },
				{ id: 2, name: '华南', children: [], disabled: false },
				{ id: 3, name: '华中', children: [], disabled: false },
			]
			let menuList2 = [
				{ id: 4, name: '华北', children: [], disabled: false },
				{ id: 5, name: '西北', children: [], disabled: false },
				{ id: 6, name: '西南', children: [], disabled: false },
			]
			let menuList3 = [
				{ id: 7, name: '东北', children: [], disabled: false },
				{ id: 8, name: '港澳台', children: [], disabled: false },
			]
			addressList.map(address => {
				if (address.id >= 310000 && address.id <= 370000) {
					menuList1[0].children.push(address);
				} else if (address.id >= 440000 && address.id <= 460000) {
					menuList1[1].children.push(address);
				} else if (address.id >= 410000 && address.id <= 430000) {
					menuList1[2].children.push(address);
				} else if (address.id >= 110000 && address.id <= 150000) {
					menuList2[0].children.push(address);
				} else if (address.id >= 610000 && address.id <= 650000) {
					menuList2[1].children.push(address);
				} else if (address.id >= 500000 && address.id <= 540000) {
					menuList2[2].children.push(address);
				} else if (address.id >= 210000 && address.id <= 230000) {
					menuList3[0].children.push(address);
				} else if (address.id >= 710000 && address.id <= 820000) {
					menuList3[1].children.push(address);
				}
			})

			menuList1.map(item => {
				let disabledNum = 0;
				item.children.map(item1 => {
					if (item1.disabled) {
						disabledNum++;
					}
				})
				if (disabledNum >= item.children.length) {
					item.disabled = true;
				}
			})
			this.menuList1 = this.isDisabled(menuList1);
			this.menuList2 = this.isDisabled(menuList2);
			this.menuList3 = this.isDisabled(menuList3);
			// console.log(this.menuList);
		},
		//判断是否需要禁用区域
		isDisabled (list) {
			list.map(item => {
				let disabledNum = 0;
				item.children.map(item1 => {
					if (item1.disabled) {
						disabledNum++;
					}
				})
				if (disabledNum >= item.children.length) {
					item.disabled = true;
				}
			})
			return list;
		},
		//返回选中数据
		submitEdit () {
			let node1 = this.$refs.tree1.getCheckedNodes();
			let node2 = this.$refs.tree2.getCheckedNodes();
			let node3 = this.$refs.tree3.getCheckedNodes();
			let data = node1.concat(node2.concat(node3));
			this.$emit('getCheckedArea', data,)
		}
	}
}
</script>
<style lang="less" scoped>
.contanier {
	position: relative;
	overflow: hidden;
}
.dialog-footer {
	text-align: right;
	.button {
		margin: 30px auto 0 auto;
		display: block;
		width: 200px;
	}
}
.area-content {
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
	.left {
		width: 150px;
		line-height: 40px;
	}
	.right {
		width: calc(100% - 150px);
		line-height: 40px;
		// display: flex;
		// flex-direction: row;
		// justify-content: space-between;

		.item {
			display: inline-block;
			width: 25%;
		}
	}
}
</style>
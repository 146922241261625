<template>
	<div class="contanier frightCompoints">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="searchKey" placeholder="商品名称、编码" clearable style="width:300px"></el-input>
			</div>

			<div class="filter-item">
				<label class="label">销售类型: </label>
				<el-select v-model="saleType" placeholder="请选择" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in saleTypeList" :key="index" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
			</div>

			<!-- 销售分组 -->
			<div class="filter-item">
				<label class="label">商品分组: </label>
				<el-select v-model="group" placeholder="请选择" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in groupOptions" :key="index" :label="item.GroupName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<!-- 商品品牌 -->
			<div class="filter-item">
				<label class="label">商品品牌: </label>
				<el-select v-model="brand" placeholder="请选择" clearable>
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="(item,index) in brandOptions" :key="index" :label="item.BrandName" :value="item.Id">
					</el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content" style="max-height:500px;overflow: auto;">
			<!-- :row-key="getRowKeys" -->
			<!-- @select='onTableSelect'  -->
			<el-table :data="tableDataList" style="width:1100px" ref="compSelectProTable" @select-all='selectAll' row-key="keys"
			 @selection-change="handleSelectionChange" :loading="loading">
				<el-table-column type="selection" width="55" reserve-selection>
				</el-table-column>
				<el-table-column prop="Name" label="商品">
					<template slot-scope="scope">
						<div class="prucuct-name-content">
							<div class="imgs-boxs">
								<img :src='imgUrl+scope.row.ImgUrl' />
							</div>

							<div class="producrs-name">
								<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
							</div>

						</div>
					</template>
				</el-table-column>
				<el-table-column prop="SpecValue" label="销售类型">
					<template slot-scope="scope">
						<span v-if="scope.row.ProductType==0">
							<span>单品销售</span>
						</span>
						<span v-else>组合销售</span>

					</template>
				</el-table-column>
				<el-table-column prop="ProductPrice" label="价格"></el-table-column>
				<el-table-column prop="Stock" label="库存"></el-table-column>

			</el-table>
			<el-checkbox style="margin-top:20px" @change="tabCheckedChange" :value="checked">当前页全选</el-checkbox>
			
			<el-pagination v-if="Total" style="margin:20px 0;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="Total">
			</el-pagination>
			
		</div>
		<div slot="footer" class="dialog-footer">
			已选中
			<span style="color:#F56C6C">{{selectCount}}</span>
			件
			<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>
	import {
		productBrandList,
		productGroupList,
		activityFreeDeliveryproductList

	} from "@/api/goods";
	import config from '@/config/index'
	export default {
		name: 'selectProduce',
		props: {
			productData: { //需要选中的商品列表
				type: Array,
				default: () => {
					return []
				}
			},
		},
		data() {
			return {
				imgUrl: config.IMG_BASE,
				loading: false,
				saleType: '',
				saleTypeList: [{
						id: 0,
						value: '单品销售'
					},
					{
						id: 1,
						value: '组合销售'
					}
				],
				searchKey: '',
				type: null,
				typeOptions: [{
						value: '单品销售',
						lable: '0'
					},
					{
						value: '组合销售',
						lable: '1'
					}
				],
				group: null,
				groupOptions: [],
				brand: null,
				brandOptions: [],
				Total:0,
				currentPage: 1,
				pageSize: 10,
				tableDataList: [],
				selectCount: 0,
				checkedAll: false,
				multipleSelection: [],
				checkedShopPro: false,
				// checked: false,
				showSelectData: []
			}
		},
		computed: {
			checked() {
				var val = this.multipleSelection;
				let ableSelectLength = 0 //获取当前页选中的数据
				var ids = val.map(item => {
					return item.ProductId
				})

				this.tableDataList.forEach(item => {
					if (ids.indexOf(item.ProductId) > -1) {
						ableSelectLength++;
					}
				});
				return (ableSelectLength > 0 && this.tableDataList.length == ableSelectLength) ? true : false;
			}
		},

		created() {
			this.getInfor();
			this.getSingleList()


		},
		mounted() {

		},
		watch: {
			productData(val) {
				// this.getSingleList();

			}
		},
		methods: {
			tabCheckedChange() {
				this.$refs['compSelectProTable'].toggleAllSelection();
			},

			//筛选数据
			async getInfor() {

				try {
					let result = await productBrandList()
					this.brandOptions = result.Result

					let result1 = await productGroupList()
					this.groupOptions = result1.Result

				} catch (e) {
					console.log(e)
				} finally {}
			},


			async getSingleList() {
				this.loading = true;
				try {
					let data = {
						KeyWord: this.searchKey, // 搜索关键字，商品名称或条形码
						ProductType: this.saleType,
						ProductGroupId: this.group,
						ProductBrandId: this.brand,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					};
					let result = await activityFreeDeliveryproductList(data);
					this.Total = result.Result.Total;
					this.tableDataList = result.Result.Results;
					this.tableDataList.map(item => {
						item.keys = item.ProductId
					})

				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					if (this.productData.length && (!this.isReady)) {
						this.$refs.compSelectProTable.clearSelection();
						this.productData.map(item => {
							this.$refs['compSelectProTable'].toggleRowSelection(item, true);
						})
						this.isReady = true;
					}
					this.loading = false
				}
			},
			//table原生全选按钮是否选中
			selectAll(e) {

			},
			//外部全选当前页
			selectCurrentAll(e) {
				this.$refs['compSelectProTable'].toggleAllSelection()
			},

			handleSelectionChange(val) {

				this.selectCount = val.length;
				this.multipleSelection = val;

			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getSingleList()
			},
			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;				
				this.getSingleList()
			},

			clearSelection() {
				this.$nextTick(() => {
					this.$refs.compSelectProTable.clearSelection();
				});
			},
			getRowKeys(row) {
				return row.id;
			},
			handleFilter() {
				this.currentPage = 1;
				this.getSingleList()
			},

			//关闭弹框，以及曝光选择的列表
			saveSelectProList() {
				if (!this.multipleSelection.length) {
					this.$message({
						type: 'error',
						message: '请选择包邮商品!'
					});
					return
				}

				this.$emit('getSelectList', false, this.multipleSelection);

			}

		}
	}
</script>
<style lang="less" scoped>
	.frightCompoints {
		.contanier {
			position: relative;
			overflow: hidden;
		}

		.content {
			overflow: hidden;
		}

		.dialog-footer {
			margin-top: 20px;
			text-align: center;

			.button {
				width: 150px;
			}
		}

		.prucuct-name-content {
			margin: 10px 0;
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-moz-box-align: center;
			-webkit-box-align: center;
			-webkit-align-items: center;
			align-items: center;
			-ms-flex-align: center;

			.imgs-boxs {
				width: 60px;
				height: 60px;
				margin-right: 10px;
			}

			img {
				width: 60px;
				height: 60px;
				display: block;
			}

			.producrs-name {
				word-break: break-all;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}

	}
</style>
